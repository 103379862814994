<template>
	<div v-if="tab !== 'Faturamento TISS'">
		<b-row>
			<b-col cols="4">
				<b-form-group class="form-group periods">
					<Periods
						label="Período"
						@select="handlePeriod"
						top="50%"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="4">
				<b-form-group class="form-group">
					<label for="patient">Paciente</label>
					<DatabaseCheckboxSelect
						id="patient"
						type="patients" label="Todos os pacientes"
						v-on:onChange="changeSelectedPatients"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="4">
				<b-form-group class="form-group">
					<label for="professional">Profissional</label>
					<DatabaseCheckboxSelect
						id="professional"
						type="professionals"
						label="Todos os profissionais"
						v-on:onChange="changeSelectedProfessionals"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="4">
				<b-form-group class="form-group">
					<label for="healthPlan">Convênio</label>
					<DatabaseCheckboxSelect
						id="healthPlan"
						type="healthPlans"
						label="Todos os convênios"
						v-on:onChange="changeSelectedHealthPlans"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="4">
				<b-form-group class="form-group">
					<label class="type-label" for="procedureType">
						Tipo de procedimento
						<b-row class="type-checkbox">
							<b-form-checkbox
								id="type-checkbox"
								class="ml-3"
								name="type-checkbox"
								v-model="filterOptions.showTypes"
							></b-form-checkbox>
							<span class="mt-1">Exibir somente itens do tipo selecionado.</span>
						</b-row>

					</label>
					<CheckboxSelect
						id="procedureType"
						label="Todos os tipos de procedimentos"
						:value="procedureTypeOptions"
						:options="procedureTypeOptions"
						v-on:onChange="changeSelectedProcedureTypes"
						placeholder="Selecionar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="4">
				<b-form-group class="form-group">
					<label for="procedures">Procedimentos</label>
					<DatabaseCheckboxSelect
						id="procedures"
						type="procedures"
						label="Todos os procedimentos"
						:procedureTypes="filterOptions.proceduresTypes"
						v-on:onChange="changeSelectedProcedures"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>
		</b-row>


		<b-row>
			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="scheduleSituations">Situação do agendamento</label>
					<CheckboxSelect
						id="scheduleSituations"
						label="Todas as situações de agendamento"
						:value="scheduleOptions"
						:options="scheduleOptions"
						v-on:onChange="changeSelectedScheduleOptions"
						placeholder="Todas as situações"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="fieldsToShow">Campos a exibir no relatório</label>
					<CheckboxSelect
						id="fieldsToShow"
						label="Todos os campos a exibir"
						:options="fieldsToShow"
						:value="fieldsToShow"
						v-on:onChange="changeSelectedFields"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>
			<b-col cols="3" class="btn-container">
				<b-button v-if="showExportButton()" variant="outline-primary" @click="getOutputsXlsx">
					Download xlsx
				</b-button>
			</b-col>
			<!-- <b-button variant="outline-primary" >Imprimir</b-button> Será implementado futuramente -->
			<b-col cols="3" class="btn-container">
				<button
					class="btn btn-primary"
					@click="generateReport"
					:class="{ 'disabled-button': !this.filterOptions.period.start || !this.filterOptions.period.end }"
				>
					Gerar relatório
				</button>
			</b-col>
		</b-row>
	</div>
	<div v-else>
		<b-row>
			<b-col cols="2">
				<b-form-group class="form-group periods">
					<Periods
						label="Período"
						@select="handlePeriod"
						top="50%"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="patient">Paciente</label>
					<DatabaseCheckboxSelect
						id="patient"
						type="patients" label="Todos os pacientes"
						v-on:onChange="changeSelectedPatients"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="procedures">Procedimentos</label>
					<DatabaseCheckboxSelect
						id="procedures"
						type="procedures"
						label="Todos os procedimentos"
						:procedureTypes="filterOptions.proceduresTypes"
						v-on:onChange="changeSelectedProcedures"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="2">
				<b-form-group class="form-group">
					<label for="search">Senha</label>
					<b-form-input
					autocomplete="off"
					v-model="filterOptions.password"
					placeholder="Buscar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="2">
				<b-form-group class="form-group">
					<label class="type-label" for="procedureType">
						Tipo de Item
					</label>
					<CheckboxSelect
						id="procedureType"
						label="Todos os tipos de item"
						:value="procedureTypeOptions"
						:options="procedureTypeOptions"
						v-on:onChange="changeSelectedProcedureTypes"
						placeholder="Selecionar"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="healthPlan">Convênio</label>
					<DatabaseCheckboxSelect
						id="healthPlan"
						type="healthPlans"
						label="Todos os convênios"
						v-on:onChange="changeSelectedHealthPlans"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="3">
				<b-form-group class="form-group">
					<label for="professional">Profissional</label>
					<DatabaseCheckboxSelect
						id="professional"
						type="professionals"
						label="Todos os profissionais"
						v-on:onChange="changeSelectedProfessionals"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="2">
				<b-form-group class="form-group">
					<label for="fieldsToShow">Campos a exibir no relatório</label>
					<CheckboxSelect
						id="fieldsToShow"
						label="Todos os campos a exibir"
						:options="fieldsToShow"
						:value="fieldsToShow"
						v-on:onChange="changeSelectedFields"
						placeholder="Pesquisar"
					/>
				</b-form-group>
			</b-col>

			<b-col cols="2" class="btn-container">
				<b-button v-if="showExportButton()" variant="outline-primary" @click="getOutputsXlsx">
					Download xlsx
				</b-button>
			</b-col>
			<!-- <b-button variant="outline-primary" >Imprimir</b-button> Será implementado futuramente -->
			<b-col cols="2" class="btn-container">
				<button
					class="btn btn-primary"
					@click="generateReport"
					:class="{ 'disabled-button': !this.filterOptions.period.start || !this.filterOptions.period.end }"
				>
					Gerar relatório
				</button>
			</b-col>

		</b-row>

	</div>
</template>

<script>
import { getCurrentClinic, userHasPermission, getCurrentUser } from '@/utils/localStorageManager'

export default {
	name: 'schedule-filters',
	components: {
		CheckboxSelect: () => import('../../components/CheckboxSelect.vue'),
		DatabaseCheckboxSelect: () => import('../../components/DatabaseCheckboxSelect.vue'),
		Periods: () => import('@/components/General/Periods'),
	},
	props: {
		tab: String,
		group: String,
		headers: Array,
	},
	data() {
		const clinic = getCurrentClinic()
		return {
			clinic: clinic,
			user: getCurrentUser(),
			filterOptions: {
				clinicId: clinic.id,
				showTypes: false,
				period: {
					start: null,
					end: null
				},
				status: null,
				patients: null,
				doctors: null,
				healthPlans: null,
				procedures: null,
				proceduresTypes: null,
				fieldsToShow: null,
				password: null,
			},
			procedureTypeOptions: [
				"Todos",
				"Consulta",
				"Exame",
				"Procedimento",
				"Retorno",
				"Telemedicina",
				"Centro cirúrgico"
			],

			// scheduleOptions: ['Todos', 'Confirmado', 'Cancelado', 'Faltou', 'Atendimento finalizado'],

			fieldsToShow: this.headers,
		}
	},
	computed: {
		scheduleOptions() {
			if (this.tab === 'Procedimento') {
				return ['Todos', 'Sem situação', 'Compareceu', 'Atendimento finalizado', 'Confirmado', 'Confirmado pelo paciente', 'Faltou', 'Cancelado', 'Cancelado pelo paciente', 'Aguardando atendimento', 'Em atendimento médico', 'Em exames', 'Dilatando', 'Pré-consulta finalizada', 'Em pré-consulta', 'Finalizado'];
			} else {
				return ['Todos', 'Confirmado', 'Cancelado', 'Faltou', 'Atendimento finalizado']
			}
		}
	},
	methods: {
		getOutputsXlsx() {
			const isLoading = this.$loading.show();
			const data = {
				"type": this.tab,
				"filters": this.filterOptions,
				"clinic_id": this.clinic.id,
				"professional_id": this.user.id,
				"tabId": window.sessionStorage.getItem('tabId')
			}
			this.api.getManagementReportsExport(data)
				.then(() => {
					this.$toast.success('Download do relatório gerencial iniciado com sucesso!')
					isLoading.hide()
				})
				.catch(err => this.$toast.error(err.message))
				.finally(() => isLoading.hide());
		},
		handlePeriod(period) {
			this.filterOptions.period.start = period.start;
			this.filterOptions.period.end = period.end;
		},
		changeSelectedFields(newValue) {
			this.filterOptions.fieldsToShow = newValue?.length ? newValue : null
		},
		changeSelectedPatients(newValue) {
			this.filterOptions.patients = newValue?.length ? newValue : null
		},
		changeSelectedProfessionals(newValue) {
			this.filterOptions.doctors = newValue?.length ? newValue : null
		},
		changeSelectedHealthPlans(newValue) {
			this.filterOptions.healthPlans = newValue?.length ? newValue : null
		},
		changeSelectedProcedureTypes(newValue) {
			this.filterOptions.proceduresTypes = []
			const translatedTypes = {
				"Consulta": "APPOINTMENT",
				"Centro Cirúrgico": "SURGERY",
				"Exame": "EXAM",
				"Procedimento": "PROCEDURE",
				"Retorno": "RETURN",
				"Telemedicina": "TELEMEDICINE",
        		"Centro cirúrgico": "SURGICAL"
			}
			if (newValue) {
				newValue.map(key => {
					this.filterOptions.proceduresTypes.push(translatedTypes[key])
				})
			} else {
				this.filterOptions.proceduresTypes = newValue?.length ? newValue : null
			}

		},
		changeSelectedProcedures(newValue) {
			this.filterOptions.procedures = newValue?.length ? newValue : null
		},
		changeSelectedScheduleOptions(newValue) {
			this.filterOptions.status = []
			const translatedStatus = {
				'Cancelado pelo paciente' : 'CANCELLED_BY_PATIENT',
				'Confirmado pelo paciente': 'CONFIRMED_BY_PATIENT',
				'Em atendimento médico': 'HAPPENING',
				'Pré-consulta finalizada' : 'PRE_FINISHED',
				'Cancelado': 'CANCELLED',
				'Confirmado': 'CONFIRMED',
				'Faltou': 'MISSED',
				'Sem situação': 'SCHEDULED',
				'Compareceu': 'ATTEND',
				'Finalizado': 'FINISHED',
				'Aguardando atendimento': 'WAITING',
				'Em atendimento': 'HAPPENING',
				'Em exames': 'EXAMINATING',
				'Dilatando': 'DILATING',
				'Pré consulta': 'PRE_FINISHED',
				'Em pré-consulta': 'IN_PRE_CONSULTATION',

				"Atendimento finalizado": "FINISHED",
			}
			if (newValue) {
				newValue.map(key => {
					this.filterOptions.status.push(translatedStatus[key])
				})
			} else {
				this.filterOptions.status = newValue?.length ? newValue : null
			}

		},

		generateReport() {
			if (!this.filterOptions.period.start || !this.filterOptions.period.end) {
				return this.$toast.warning('Selecione o período')
			}
			this.$emit('onChangeFilters', this.filterOptions)
		},
		showExportButton() {
			return (this.group === 'Agenda' && userHasPermission('ReGerAge2')) ||
				(this.group === 'Processos' && userHasPermission('ReGerProc2')) || 
				(this.group === 'Atendimento' && userHasPermission('ReGerAge2')) ||
				(this.group === 'Procedimentos')
		}
	}
}
</script>

<style lang="scss" scoped>

.type-label {
	display: flex;
	flex-direction: row;
	max-width: 50ch;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	.type-checkbox {
		display: contents;
		span {
				color: var(--type-active) !important;
				font-size: 12px !important;
				max-width: 32ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
		}
	}
}
label {
	width: 100%;
	text-align: left !important;
}

.input-container {
	display: flex;
	flex-direction: column;
}

.btn-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	button {
		text-align: center;
		width: 100%;
		height: 38px;
		margin-top: 5px;
	}

	.disabled-button {
		cursor: default;
		background-color: var(--neutral-500);

		&:hover {
			background-color: var(--neutral-500);
			cursor: not-allowed;
		}
	}
}
</style>
